<template>
    <div class="table">
        <!-- 头部 -->
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <!-- 表格内容 -->
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="活动名称:">
                    <el-input v-model="queryParams.name" placeholder="请输入活动名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="上线" :value="2"></el-option>
                        <el-option label="下线" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-button type="primary" @click="add()">新增活动</el-button>
            </div>
            <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable">
                <el-table-column prop="id" label="id" width="120" align="center"></el-table-column>
                <el-table-column label="活动id" prop="resource_id" align="center" width="120"></el-table-column>
                <el-table-column label="活动名称" prop="name" align="center" width="220"></el-table-column>
                <el-table-column label="满足条件" prop="price" align="center" width="100"></el-table-column>
                <el-table-column label="优惠券类型" prop="resource " align="center" width="120">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.resource  === 1">品牌</el-tag>
                        <el-tag type="primary" v-if="scope.row.resource  === 3">专场</el-tag>
                        <el-tag type="primary" v-if="scope.row.resource  === 0">全场</el-tag>
                        <el-tag type="primary" v-if="scope.row.resource === 4">套装购</el-tag>
                        <el-tag type="primary" v-if="scope.row.resource === 5">拼团</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="满减或折" prop="ty " align="center" width="120">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.ty  === 1">满减</el-tag>
                        <el-tag type="primary" v-if="scope.row.ty  === 2">满件数</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="开始时间" prop="ctime" align="center"></el-table-column>
                <el-table-column prop="etime" label="结束时间" align="center"></el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="200">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="170" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 删除 -->
        <el-dialog title="是否删除数据" :visible.sync="removeVisible" width="300px" center>
            <div class="del-dialog-cnt">删除后数据无法恢复，是否确认删除？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="removeVisible = false">取 消</el-button>
                <el-button type="primary">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import loadEvents from '@/utils/loading'
import Url from '@/components/Url';
import { request } from '@/common/request';
import { deal } from '@/common/main';
let loads = new loadEvents();
export default {
    name: 'seller',
    components: {
        Breadcrumb,
        Pagination,
        Url
    },
    data() {
        return {
            addDiscountVisible: false,
            multiple: false,
            header: {},
            menuList: ['营销', '满送劵'],
            queryParams: {
                ctime: '',
                etime: '',
                searchName: '',
                page: 1,
                pagesize: 10
            },
            //验证规则
            rules: {

            },
            loading: false,
            pageInfo: {},
            addVisible: false,
            removeVisible: false,
            confirmVisible: false,
            confirmContent: '',
            title: '加载中',
            mode: 'add',
            form: {
                state: 2
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            }
        };
    },
    watch: {
        $route(to, from) {
            let GiveDiscount = from.fullPath.indexOf('/activity-GiveDiscount')
            if (GiveDiscount !== -1) {
                this.loadData()
            }
        }
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadData();
    },
    methods: {
        // 添加优惠券
        addDiscount() {
            this.addVisible = true;
            // this.getDiscount()
        },
        // 已选优惠券删除
        deleteDiscount(index) {
            this.discountList.splice(index, 1)
        },
        //储值活动列表
        loadData() {
            this.loading = true;
            let _this = this
            request.get('/coupon/activity/coupon/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    console.log(22222, this.pageInfo)
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map(function (val) {
                        val.ctime = deal.timestamp(val.ctime, 'unix');
                        val.etime = deal.timestamp(val.etime, 'unix');
                        return val;
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        loadDatas() {
            this.loading = true;
            request.get('/coupon/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.discountList = ret.data.list;
                    this.discountList.map(function (val) {
                        val.ctime = deal.timestamp(val.ctime, 'unix');
                        val.etime = deal.timestamp(val.etime, 'unix');
                        return val;
                    });
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        let param = Object.assign({}, this.form);
                        param.discountList = this.discountList
                        param.ctime = deal.timestamp(param.time[0]);
                        param.etime = deal.timestamp(param.time[1]);
                        console.log(44444, param)
                        var url = this.mode == 'add' ? '/coupon/activity/coupon/add' : '/coupon/activity/coupon/edit';
                        request.post(url, param).then(ret => {
                            if (ret.code == 1) {
                                this.addDiscountVisible = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
        //新增
        add() {
            this.$router.push({ path: '/activity-GiveDiscountAdd' })
        },
        //编辑
        edit(index, row) {
            this.mode = 'edit';
            this.title = '编辑活动';
            this.$router.push({ path: '/activity-GiveDiscount', query: { 'id': row.id } })
        },
        remove(index, row) {
            const item = this.pageInfo.list[index];
            this.form = item;
            this.removeVisible = true;
        },
        //状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.form.ctime = deal.timestamp(this.form.ctime);
            this.form.etime = deal.timestamp(this.form.etime);
            this.confirmVisible = true;
        },
        toggleAdd(val) {
            this.addVisible = val
        },
        getAddGood(val) {
            this.discountList = val
        },
        // 确定下线
        stateData() {
            request.post('/coupon/activity/coupon/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        }
    }
};
</script>

<style scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}

.del-dialog-cnt {
    font-size: 16px;
    text-align: center;
}

.table {
    width: 100%;
    font-size: 14px;
}

.red {
    color: #ff0000;
}

.green {
    color: green;
}

.mr10 {
    margin-right: 10px;
    width: 50%;
}

.crop-demo-btn {
    position: absolute;
    right: 0px;
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #409eff;
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
    box-sizing: border-box;
    float: right;
}

.crop-input {
    position: absolute;
    width: 100px;
    height: 40px;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.pre-img {
    width: 100px;
    height: 100px;
    background: #f8f8f8;
    border: 1px solid #eee;
    border-radius: 5px;
    margin-top: 10px;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.avatar {
    width: 100px;
    height: 100px;
    font-size: 28px;
    line-height: 100px;
}

.avatar-uploader .avatar-uploader-icon {
    line-height: 100px;
}

.cell {
    text-align: center;
}

.el-input--suffix .el-input__inner {
    padding-right: 15px;
}

.up {
    margin-top: 20px;
}
</style>